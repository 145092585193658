<template>
    <div class="detail_box">
        <div
            class="top_title"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <div class="title_box">
                <i class="iconfont icon-huikuan"></i>
                线索详情
                <span
                    :class="
                        clue.status == 4
                            ? 'bg wxiao'
                            : clue.status == 5
                            ? 'bg gjz'
                            : clue.status == 8
                            ? 'bg yjc'
                            : clue.status == 12
                            ? 'bg dfp'
                            : clue.status == 13
                            ? 'bg ych'
                            : clue.status == 14
                            ? 'bg yfp'
                            : clue.status == 15
                            ? 'bg ycj'
                            : 'bg'
                    "
                    >{{ $tableDataHandle.clueStatus(clue.status) }}</span
                >
                <el-button @click="onClose()">
                    <i class="el-icon-arrow-left"></i> 返回列表
                </el-button>
            </div>
            <div class="title_two">
                <div>
                    <span class="cicle"><i>线索</i></span>
                    <p>分配部门：{{ clue.followDepartmentName || '- -' }}</p>
                    <p style="margin-left: 48px">
                        负责人：{{ clue.followAdminName || '- -' }}
                    </p>
                    <!-- <p style="margin-left:48px;">
                        指导人：{{
                            personConfigList && personConfigList.length > 0
                                ? personConfigList[0].receiveAdminName
                                : '- -'
                        }}
                        <i
                            class="iconfont icon-zhankai1"
                            v-if="personConfigList && personConfigList.length > 1"
                        ></i>
                    </p> -->
                    <p style="margin-left: 48px">
                        创建人：{{ clue.adminName || '- -' }}
                    </p>
                    <p style="margin-left: 48px; flex: 1">
                        协作人：
                        <span
                            v-if="
                                clue.lineSynergyList &&
                                clue.lineSynergyList.length > 0
                            "
                        >
                            <span v-for="item in clue.lineSynergyList">
                                {{ item.synergyAdminName }}</span
                            >
                        </span>
                        <el-button
                            v-if="
                                clue.status == 5 &&
                                clue.followAdminId == adminId
                            "
                            type="primary"
                            @click="onAddCL()"
                            style="
                                color: #fff;
                                background: #2370eb;
                                border-color: #2370eb;
                                padding: 3px 10px;
                                margin-left: 10px;
                            "
                            size="mini"
                            class="el-icon-plus"
                        >
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-button
                        v-if="clue.status == 5 && clue.followAdminId == adminId"
                        type="text"
                        @click="onInvalid()"
                        style="color: #666666; margin-right: 12px"
                    >
                        <i class="el-icon-remove" style="color: #435ebe"></i>
                        无效
                    </el-button>
                    <el-button
                        v-if="clue.status == 5 && clue.followAdminId == adminId"
                        type="text"
                        @click="onGive()"
                        style="color: #666666; margin-right: 12px"
                    >
                        <i
                            class="el-icon-delete-solid"
                            style="color: #da5120"
                        ></i>
                        放弃
                    </el-button>
                    <el-button
                        v-if="clue.status == 5 && clue.followAdminId == adminId"
                        type="text"
                        @click="onDeal()"
                        style="color: #666666"
                    >
                        <i
                            class="iconfont icon-dingdan"
                            style="color: #feb516; font-size: 14px"
                        ></i>
                        完成
                    </el-button>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="left_box">
                <div class="left_title_box">线索信息</div>
                <ClueInfo
                    style="flex: 1; overflow: auto"
                    ref="clueInfo"
                    @onHistory="onHistory"
                    @addCust="addCust"
                    @getBtn="getClue"
                    @relationOk="relationOk"
                ></ClueInfo>
                <div class="bottom-but" v-if="this.clue.status != 8">
                    <span>
                        <el-button
                            round
                            type="primary"
                            size="mini"
                            style="
                                background: none;
                                border-color: #d5d6df;
                                color: #666666;
                            "
                            @click="onClose()"
                            >取消</el-button
                        >
                        <el-button
                            round
                            type="primary"
                            style="
                                background: #2370eb;
                                border-color: #2370eb;
                                color: #fff;
                            "
                            size="mini"
                            @click="onSubmit()"
                            >保存</el-button
                        ></span
                    >
                </div>
            </div>
            <div class="right_box">
                <div
                    v-if="clue.customerBOList && clue.customerBOList.length > 0"
                >
                    <div class="left_title_box">
                        客户：{{ clue.customerBOList[0].companyName }}
                        <el-button
                            v-show="cusomerFold"
                            @click="cusomerFold = !cusomerFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!cusomerFold"
                            @click="cusomerFold = !cusomerFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </div>
                    <el-collapse-transition>
                        <Customer
                            @changeCusomerFold="
                                (boolean) => {
                                    this.cusomerFold = boolean;
                                }
                            "
                            v-show="cusomerFold"
                            ref="customer"
                        ></Customer>
                    </el-collapse-transition>
                </div>
                <div
                    style="margin-bottom: 8px"
                    v-if="isAddCust && !clue.customerBOList.length"
                >
                    <div class="left_title_box">创建客户</div>
                    <div>
                        <AddCust @close="addClose" ref="addcust" />
                    </div>
                </div>
                <div>
                    <div class="title">
                        <span>
                            <i class="shu"></i>
                            往来记录</span
                        >
                        <p>
                            <el-button
                                v-if="this.clue.status != 8"
                                @click="onAddDealings"
                                type="primary"
                                size="mini"
                                style="
                                    margin-right: 12px;
                                    color: #2370eb;
                                    background: #fff;
                                    border-color: #2370eb;
                                "
                            >
                                新增往来
                            </el-button>
                            <el-button
                                v-show="dealingFold"
                                @click="dealingFold = !dealingFold"
                                type="text"
                                style="color: #2162cb"
                            >
                                收起 <i class="el-icon-arrow-up"></i>
                            </el-button>
                            <el-button
                                v-show="!dealingFold"
                                @click="dealingFold = !dealingFold"
                                type="text"
                                style="color: #2162cb"
                            >
                                展开 <i class="el-icon-arrow-down"></i>
                            </el-button>
                        </p>
                    </div>
                    <div>
                        <el-collapse-transition>
                            <DealingHistory
                                ref="dealingHistory"
                                v-show="dealingFold"
                                @close="clueDealingList"
                            />
                        </el-collapse-transition>
                    </div>
                </div>

                <div>
                    <div class="left_title_box">
                        线索历史
                        <el-button
                            v-show="historyFold"
                            @click="historyFold = !historyFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!historyFold"
                            @click="historyFold = !historyFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </div>
                    <el-collapse-transition>
                        <ClueHistory
                            v-show="historyFold"
                            ref="clueHistory"
                        ></ClueHistory>
                    </el-collapse-transition>
                </div>
            </div>
        </div>

        <!-- 信息编辑历史 -->
        <el-drawer
            :visible.sync="drawerHistory"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    信息编辑历史
                </div>
            </template>
            <InfoHistory @close="handleClose" ref="infoHistory" />
        </el-drawer>
        <!-- 添加往来 -->
        <el-dialog
            :visible.sync="drawerDealing"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加往来
                </div>
            </template>
            <div>
                <AddDealing ref="adddealing" @close="handleClose" />
            </div>
        </el-dialog>
        <!-- 弹窗 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ dialogTitle }}
                </div>
            </template>
            <div class="content">
                <p v-html="dialogContent"></p>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmitDialog"
                    >确定</el-button
                >
            </div>
        </el-dialog>
        <!-- 添加协作人 -->
        <el-dialog
            :visible.sync="dialogClueAssist"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加协作人
                </div>
            </template>
            <div>
                <AddAssist ref="addassist" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getClue,
    clueHistory,
    clueDealingList,
    personConfigGet,
} from '@/api/clue/depClue.js';
import { clueUpDate } from '@/api/clue/meClue';
import { configList } from '@/api/configure/configure.js';
import ClueInfo from './clue/clue.vue';
import DealingHistory from './dealing/dealing.vue';
import ClueHistory from './history/history.vue';
import Customer from './customer/customer.vue';
import AddCust from './customer/addCust/addCust.vue';
import AddDealing from './dealing/addDealing.vue';
import AddAssist from '../addAssist/addAssist.vue';
import InfoHistory from './infoHistory/infoHistory.vue';
export default {
    data() {
        return {
            cusomerFold: false,
            isCustomer: false,
            dealingFold: true,
            historyFold: true,
            direction: 'rtl',
            drawerHistory: false,
            clue: {},
            personConfigList: [],
            isAddCust: false,
            drawerDealing: false,
            dialog: false,
            dialogTitle: '',
            dialogContent: '',
            dialogClueAssist: false,
            status: 0,
            adminId: '',
            isCoop: false,
        };
    },
    components: {
        AddAssist,
        ClueInfo,
        DealingHistory,
        ClueHistory,
        Customer,
        AddCust,
        AddDealing,
        InfoHistory,
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.adminId = sessionStorage.getItem('adminId');
            this.isAddCust = false;
            this.getClue();
            this.clueHistory();
            // this.configList();
            this.clueDealingList();
        },
        //线索详情
        getClue() {
            let data = {
                param: {
                    id: this.$route.query.id,
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            };
            getClue(data).then((res) => {
                if (res.code == 200) {
                    this.clue = res.data;

                    setTimeout(() => {
                        this.$refs.clueInfo.getData(res.data);
                    });
                    let nav = JSON.parse(
                        sessionStorage.getItem('nav')
                    ).children;
                    if (
                        res.data.customerBOList &&
                        res.data.customerBOList.length > 0
                    ) {
                        this.isCustomer = true;
                        setTimeout(() => {
                            this.$refs.customer.getData(
                                res.data.customerBOList
                            );
                        });
                        for (let i in nav) {
                            if (nav[i].route == 'depClue') {
                                this.isCustomer = true;
                                setTimeout(() => {
                                    this.$refs.customer.getData(
                                        res.data.customerBOList
                                    );
                                });
                                break;
                            }
                        }
                    }
                }
            });
        },
        relationOk() {
            let data = {
                param: {
                    id: this.$route.query.id,
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            };
            getClue(data).then((res) => {
                console.log(res.data);
                if (res.code == 200) {
                    let nav = JSON.parse(
                        sessionStorage.getItem('nav')
                    ).children;
                    this.clue.customerBOList = res.data.customerBOList;
                    this.$refs.clueInfo.getCustomerBOList(
                        res.data.customerBOList
                    );
                    if (
                        res.data.customerBOList &&
                        res.data.customerBOList.length > 0
                    ) {
                        if (
                            res.data.customerBOList[0].followAdminId ==
                            sessionStorage.getItem('adminId')
                        ) {
                            this.isCustomer = true;
                            setTimeout(() => {
                                this.$refs.customer.getData(
                                    res.data.customerBOList
                                );
                            });
                        }
                        for (let i in nav) {
                            if (nav[i].route == 'depClue') {
                                this.isCustomer = true;
                                setTimeout(() => {
                                    this.$refs.customer.getData(
                                        res.data.customerBOList
                                    );
                                });
                                break;
                            }
                        }
                    }
                }
            });
        },
        addClose() {
            let data = {
                param: {
                    id: this.$route.query.id,
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            };
            getClue(data).then((res) => {
                if (res.code == 200) {
                    let nav = JSON.parse(
                        sessionStorage.getItem('nav')
                    ).children;
                    this.clue.customerBOList = res.data.customerBOList;
                    this.$refs.clueInfo.getCustomerBOList(
                        res.data.customerBOList
                    );
                    if (
                        res.data.customerBOList &&
                        res.data.customerBOList.length > 0
                    ) {
                        if (
                            res.data.customerBOList[0].followAdminId ==
                            sessionStorage.getItem('adminId')
                        ) {
                            this.isCustomer = true;
                            setTimeout(() => {
                                this.$refs.customer.getData(
                                    res.data.customerBOList
                                );
                            });
                        }
                        for (let i in nav) {
                            if (nav[i].route == 'depClue') {
                                this.isCustomer = true;
                                setTimeout(() => {
                                    this.$refs.customer.getData(
                                        res.data.customerBOList
                                    );
                                });
                                break;
                            }
                        }
                    }

                    this.isAddCust = false;
                }
            });
        },
        // 线索历史
        clueHistory() {
            let data = {
                param: {
                    lineId: this.$route.query.id,
                },
            };
            clueHistory(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.clueHistory.getData(res.data.list);
                }
            });
        },
        // 线索往来
        clueDealingList() {
            let data = {
                param: {
                    lineId: this.$route.query.id,
                },
            };
            clueDealingList(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.dealingHistory.getData(res.data.list);
                }
            });
        },
        // 指导人
        configList() {
            let data = {
                param: {
                    shareAdminId: sessionStorage.getItem('adminId'),
                },
            };
            personConfigGet(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.personConfigList = res.data;
                }
            });
        },
        // 创建客户
        addCust(i, data) {
            console.log(data);
            if (i == 1) {
                this.isAddCust = true;
                setTimeout(() => {
                    this.$refs.addcust.getData(1, this.$refs.clueInfo.form);
                });
            }
            if (i == 2) {
                this.isAddCust = true;
                setTimeout(() => {
                    this.$refs.addcust.getData(2, data);
                });
            }
        },
        onHistory() {
            this.drawerHistory = true;
            let data = {
                param: {
                    lineId: this.$route.query.id,
                    type: 2,
                },
                pageSize: 0,
                pageNum: 0,
            };
            clueHistory(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.infoHistory.getData(res.data.list);
                }
            });
        },
        onAddDealings() {
            this.drawerDealing = true;
        },
        onSubmit() {
            let clue = this.$refs.clueInfo.form;
            if (!clue.productClue) {
                return this.$message.error('请选择线索产品');
            } else if (!clue.remark) {
                return this.$message.error('请输入需求');
            }
            if (this.clue.adminId == sessionStorage.getItem('adminId')) {
                if (
                    this.clue.status == 12 ||
                    this.clue.status == 13 ||
                    this.clue.status == 14 ||
                    this.clue.status == 5
                ) {
                    if (!clue.clueType) {
                        return this.$message.error('请选择线索类型');
                    }
                    if (!clue.retentionMethod) {
                        return this.$message.error('请选择留资方式');
                    }
                }
            }
            let data = {
                param: clue,
            };
            console.log(this.$refs.clueInfo.customerTagList);
            data.param.provinceId = this.$refs.clueInfo.addressArr[0];
            data.param.cityId = this.$refs.clueInfo.addressArr[1];
            data.param.lineTagList = this.$refs.clueInfo.customerTagList;
            data.param.id = this.$route.query.id;
            delete data.param.status;
            console.log(data.param);
            for (let i in data.param.lineDomainDOList) {
                if (data.param.lineDomainDOList[i].domain == '') {
                    data.param.lineDomainDOList.splice(i, 1);
                }
            }
            for (let i in data.param.lineContactDOList) {
                if (
                    !data.param.lineContactDOList[i].contactContent ||
                    data.param.lineContactDOList[i].contactContent == ''
                ) {
                    data.param.lineContactDOList.splice(i, 1);
                }
            }
            clueUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$router.go(-1);
        },
        handleClose() {
            this.dialog = false;
            this.drawerHistory = false;
            this.drawerDealing = false;
            this.dialogClueAssist = false;
            this.getBtn();
        },
        // 协作
        onAddCL() {
            this.dialogClueAssist = true;
            setTimeout(() => {
                this.$refs.addassist.getAssist(this.clue);
            });
        },
        // 无效
        onInvalid() {
            this.dialog = true;
            this.status = 4;
            this.dialogTitle = '无效';
            this.dialogContent =
                '<sapn><i class="iconfont icon-shangchuanshibaixian-copy"></i></span>' +
                '<br />即将标记本线索为无效状态，是否确认？';
        },
        // 放弃
        onGive() {
            this.dialog = true;
            this.status = 15;
            this.dialogTitle = '放弃';
            this.dialogContent =
                '<sapn><i class="iconfont icon-shangchuanshibaixian-copy"></i></span>' +
                '<br />即将标记本线索为放弃状态，是否确认？';
        },
        // 成交
        onDeal() {
            this.dialog = true;
            this.status = 8;
            this.dialogTitle = '完成';
            this.dialogContent =
                '<sapn><i class="iconfont icon-shangchuanshibaixian-copy"></i></span>' +
                '<br />即将标记本线索为完成状态，是否确认？';
        },
        onSubmitDialog() {
            let data = {
                param: {
                    id: this.$route.query.id,
                    status: this.status,
                },
            };
            clueUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.dialog = false;
                    this.onClose();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.detail_box {
    display: flex;
    flex-direction: column;
    height: 100%;
    .top_title {
        background-color: #fff;
        margin-bottom: 12px;
        font-size: 16px;
        padding: 18px 15px 18px 35px;
        width: 100%;
        box-sizing: border-box;
        .title_box {
            display: block;
            line-height: 1.2;
            margin-bottom: 25px;
            font-size: 16px;
            color: #333;
            > i {
                color: #2370eb;
                font-size: 18px;
            }
            button {
                background: rgb(255, 255, 255);
                color: rgb(35, 112, 235);
                border-color: rgb(35, 112, 235);
                padding: 5px 10px;
                float: right;
            }
        }
    }
    .top_title .center_info {
        flex: 1;
        margin-left: 10px;
    }
    .center_info p:first-child {
        margin-bottom: 8px;
    }
    .top_title .cicle {
        color: #fff;
        width: 20px;
        height: 20px;
        background: #2370eb;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
    .top_title .cicle i {
        font-size: 16px;
        display: inline-block;
        font-style: normal;
        transform: scale(0.5, 0.5);
        white-space: nowrap;
        line-height: 1.2;
    }
    .top_title div:last-child {
        font-size: 14px;
        width: 100%;
        display: flex;
        span {
            cursor: pointer;
        }
    }
}
.info {
    display: flex;
    flex: 1;
    justify-content: space-between;
    background-color: #f0f2f4;
    min-height: 0;
    .left_box {
        flex: 2;
        margin-right: 10px;
        min-height: 0;
        overflow: auto;
        display: flex;
    }
    > div {
        background-color: #fff;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .left_title_box {
            height: 60px;
            border-bottom: 1px solid #e5e5e5;
            line-height: 60px;
            position: relative;
            font-size: 14px;
            font-weight: bold;
            padding-left: 15px;
        }
        .left_title_box:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            width: 2px;
            background: #2370eb;
        }
        .bottom-but {
            width: 100%;
            min-height: 48px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #e5e5e5;
            span {
                margin-left: auto;
                margin-right: 20px;
            }
            button {
                width: 90px;
            }
        }
    }
    .right_box {
        flex: 1;
        overflow-y: auto;
        .interval {
            height: 6px;
            background-color: #f5f7fe;
        }
        //    background-color: #f5f7fe;
        //    > div + div {
        //        margin-top: 6px;
        //    }
        //    > div {
        //        background-color: #fff;
        //    }
        .record {
            padding-left: 30px;
            position: relative;
        }
        .record:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            width: 2px;
            background: #2370eb;
        }
        .history_list {
            .item:first-child {
                border-top: 1px solid #eee;
            }
        }
    }
}
.title_two {
    color: #666;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div:nth-child(1) {
        display: flex;
        flex: 1.5;
        align-items: center;
    }
    div:nth-child(2) {
        display: flex;
        flex: 0.5;
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }
}

.bg {
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 15px;
    margin-left: 10px;
}
.gjz {
    background-color: #d7ecff;
    color: #3b99f5;
}
.dfp {
    background-color: #fff1d9;
    color: #ff962b;
}
.yfp {
    background-color: #e9f2ff;
    color: #2370eb;
}
.wxiao {
    background-color: #ffecf0;
    color: #da5120;
}
.yfq {
    background-color: #fff1f1;
    color: #d0021b;
}
.ycj {
    background-color: #dee2ff;
    color: #001df7;
}
.ych {
    background-color: #ffeada;
    color: #d7621c;
}

.title {
    height: 49px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    font-size: 13px;
    font-weight: 600;
    span {
        display: flex;
        .shu {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: #2370eb;
            margin-right: 12px;
        }
    }
}
.content {
    height: 200px;
    p {
        text-align: center;
        margin-top: 50px;
        font-size: 14px;
        color: #333;
        line-height: 30px;
        font-weight: 560;
        /deep/ .iconfont {
            color: #f5a623;
            font-size: 50px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        margin-left: auto;
        margin-right: 20px;
    }
    button {
        width: 72px;
    }
}
</style>
