<template>
    <div class="assist">
        <div class="assist-box">
            <el-form label-position="left" size="small" label-width="90px">
                <div class="customer_info">
                    <el-form-item label="线索产品"
                        >{{ $tableDataHandle.productCategory(data.productClue) }}
                    </el-form-item>
                    <el-form-item label="客户名称">
                        {{ data.companyName ? data.companyName : '- -' }}
                    </el-form-item>
                    <el-form-item label="协作人" required v-for="(item, index) in lineSynergyList">
                        <p>
                            <el-select
                                v-model="item.synergyAdminId"
                                filterable
                                placeholder="请选择或输入关键字搜索"
                                clearable
                                size="small"
                                style="width:270px"
                            >
                                <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.nickName"
                                    :value="item.id"
                                    :disabled="item.id == adminId"
                                >
                                </el-option>
                            </el-select>
                            <el-button
                                v-show="index == 0"
                                @click="onAddAssist"
                                type="primary"
                                circle
                                icon="el-icon-plus"
                                size="mini"
                                style="border-color:#2370EB;background:none;color:#2370EB;padding:3px;margin-left:11px"
                            ></el-button>
                            <el-button
                                v-show="index > 0"
                                @click="onRemove(item)"
                                type="primary"
                                circle
                                icon="el-icon-minus"
                                size="mini"
                                style="border-color:#2370EB;background:none;color:#2370EB;padding:3px;margin-left:11px"
                            ></el-button>
                        </p>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { roleList } from '@/api/customer/customer';
import { clueAddSynergy } from '@/api/clue/meClue';
export default {
    data() {
        return {
            data: '',
            options: [],
            value: '',
            lineSynergyList: [
                {
                    synergyAdminId: '',
                },
            ],
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    methods: {
        getAssist(row) {
            this.data = row;
            let data = {
                param: {
                    departmentId: sessionStorage.getItem('departmentId'),
                    roleTypeList: [2, 3, 4, 8, 9, 10],
                },
            };
            roleList(data).then((res) => {
                this.options = res.data;
                if (this.data.lineSynergyList && this.data.lineSynergyList.length > 0) {
                    this.lineSynergyList = this.data.lineSynergyList;
                }
            });
        },
        onAddAssist() {
            this.lineSynergyList.push({ synergyAdminId: '' });
        },
        onRemove(item) {
            var index = this.lineSynergyList.indexOf(item);
            if (index !== -1) {
                this.lineSynergyList.splice(index, 1);
            }
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,

                    lineSynergyList: this.lineSynergyList,
                },
            };
            let newArr = this.lineSynergyList.map((value) => value.synergyAdminId);
            //将这个数组去重
            let DuplicateDelete = new Set(newArr);
            //判断两个数组的长度是否相等
            if ([...DuplicateDelete].length != this.lineSynergyList.length) {
                return this.$message.error('协作人不能重复');
            }
            clueAddSynergy(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                }
                this.onClose();
                this.lineSynergyList = [
                    {
                        synergyAdminId: '',
                    },
                ];
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.assist {
    width: 100%;

    min-height: 374px;
    display: flex;
    flex-direction: column;

    .assist-box {
        flex: 1;
        padding: 20px 0 0 94px;
        color: #333333;
        font-size: 14px;
        max-height: 450px;
        overflow: auto;
        .user {
            padding-bottom: 16px;
            display: flex;
            align-items: center;
            .left-spot {
                display: inline-block;
                width: 8px;
                height: 8px;
                background: #2370eb;
                border-radius: 50%;
                margin-right: 20px;
                margin-left: -22px;
            }
        }
        .user:last-child {
            .left-spot {
                display: inline-block;
                width: 7px;
                height: 7px;
                background: #fff;
                border: 1px solid #2370eb;
                border-radius: 50%;
                margin-right: 20px;
                margin-left: -22px;
            }
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
