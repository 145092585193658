<template>
    <div class="customer_box">
        <el-form label-position="left" size="small" label-width="90px">
            <div class="customer_info">
                <p class="toast_info">基本信息</p>
                <el-form-item label="客户类型">
                    {{ customerData.enterpriseType == 1 ? '企业' : '个人' }}
                </el-form-item>
                <el-form-item label="客户标签">
                    <div class="label_box" v-if="customerTagList.length > 0">
                        <span
                            v-for="item in customerTagList"
                            :key="item.id"
                            :class="item.category == 0 ? 'blue' : 'orange'"
                        >
                            {{ item.tagName }}</span
                        >
                    </div>
                    <div v-else>- -</div>
                </el-form-item>
                <el-form-item label="客户名称">
                    {{ customerData.companyName || '- -' }}
                </el-form-item>
                <el-form-item label="域名">
                    {{ customerData.companyDomain || '- -' }}
                </el-form-item>
                <el-form-item
                    :label="index == 0 ? '关联域名' : ''"
                    v-for="(item, index) in customerData.customerDomainList"
                >
                    {{ item.domain }}
                </el-form-item>

                <el-form-item
                    :label="index == 0 ? '联系方式' : ''"
                    v-for="(
                        item, index
                    ) in customerData.customerMainContactList"
                >
                    {{
                        $tableDataHandle.contactWay(item)
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{
                        item.contactContent
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.contactName }}
                </el-form-item>
                <!-- <el-form-item label="预计用户数">
                    {{ customerData.number || 0 }}
                </el-form-item>

                <el-form-item label="预计年限">
                    {{ customerData.term || 0 }}
                </el-form-item> -->
                <!-- <el-form-item label="预计用户数">
                    {{
                        clueData.estimateNumber
                            ? $MailStatus.echoEstimateNumber(customerData.estimateNumber) + '人'
                            : '- -'
                    }}
                </el-form-item>
                <el-form-item label="预计年限">
                    {{
                        clueData.estimateTerm
                            ? $MailStatus.echoEstimateTerm(customerData.estimateTerm) + '年'
                            : '- -'
                    }}
                </el-form-item> -->
                <el-form-item label="原邮箱品牌">
                    {{ $tableDataHandle.primaryEmailBrand(customerData) }}
                </el-form-item>
                <el-form-item label="到期时间">
                    {{ customerData.customerExpireTime || '- -' }}
                </el-form-item>
                <el-form-item label="所在地">
                    {{
                        $address.address(
                            customerData.provinceId,
                            customerData.cityId
                        )
                    }}
                </el-form-item>
                <el-form-item label="地址">
                    {{ customerData.address || '- -' }}
                </el-form-item>
                <el-form-item label="联系备注">
                    {{ customerData.contactRemark || '- -' }}
                </el-form-item>
                <el-form-item label="所属行业">
                    {{ $tableDataHandle.industry(customerData) }}
                </el-form-item>
                <el-form-item label="备注">
                    {{ customerData.remark || '- -' }}
                </el-form-item>
            </div>
            <div
                class="contact_info"
                v-for="item in customerData.customerContactList"
                :key="item.id"
            >
                <p class="toast_info">联系人信息</p>
                <el-form-item label="联系人">
                    {{ item.contactName || '- -' }}
                </el-form-item>
                <el-form-item
                    label="联系方式"
                    v-for="itm in item.customerContactWayDOList"
                >
                    {{
                        $tableDataHandle.contactWay(itm)
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{
                        itm.contactContent
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.contactName }}
                </el-form-item>
            </div>
        </el-form>
        <div style="text-align: center">
            <el-button
                type="text"
                @click="changeCusomerFold"
                style="color: #2162cb"
            >
                收起 <i class="el-icon-arrow-up"></i>
            </el-button>
        </div>
    </div>
</template>

<script>
import { relationList } from '@/api/user/label.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            customerData: {},
            customerTagList: [],
        };
    },
    methods: {
        getData(data) {
            console.log(data);
            this.customerData = data[0];
            if (data[0].id) {
                this.relationList(data[0].id);
            }
        },
        changeCusomerFold() {
            this.$emit('changeCusomerFold', false);
        },
        // 获取所属标签
        relationList(id) {
            let data = {
                param: {
                    bandingTableType: 2,
                    bandingId: id,
                },
            };
            relationList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerTagList = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.customer_box {
    border-bottom: 1px solid #e5e5e5;
    .el-form {
        .el-form-item {
            margin-bottom: 6px !important;
        }
        /deep/ .el-form-item__label {
            color: #666 !important;
            font-weight: normal !important;
        }
        /deep/ .el-form-item__content {
            color: #333 !important;
            font-weight: bold !important;
        }
    }
    .label_box {
        span {
            display: inline-block;
            padding: 5px 10px;
            font-size: 14px;
            border: 1px solid #999;
            border-radius: 2px;
            line-height: 1;
            font-weight: normal !important;
        }
        span + span {
            margin-left: 10px;
        }
        .blue {
            border-color: #2370eb;
            color: #2370eb;
        }
        .orange {
            border-color: #fa8c15;
            color: #fa8c15;
        }
    }
    .contact_info {
        padding-left: 20px;
        padding-right: 10px;
        border-top: 1px dashed #e5e5e5;
        border-bottom: 1px dashed #e5e5e5;
    }
    .customer_info {
        padding-left: 20px;
        padding-right: 10px;
    }
}

.toast_info {
    position: relative;
    height: 32px;
    line-height: 32px;
    width: 100%;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-top: 5px;
}
.toast_info:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    background: #2370eb;
    border-radius: 50%;
}
</style>
